<template>
  <v-input
    v-if="visible"
    v-model="value"
    :error-messages="meta.dirty || meta.touched ? errors : []"
    @blur="handleBlur"
  >
    <v-field
      v-bind="$attrs"
      :label="label"
      :center-affix="false"
    >
      <template #append-inner>
        <mtf-field-icon
          :meta="meta"
          :has-errors="hasErrors"
        />
      </template>

      <div class="w-100 mx-2">
        <v-toolbar
          class="w-100"
          density="compact"
          rounded
          color="transparent"
        >
          <v-spacer />
          <v-slide-x-reverse-transition leave-absolute>
            <v-chip
              v-if="hasErrors()"
              class="mt-2"
              color="error"
              label
            >
              <v-icon
                start
                icon="mdi-alert-circle-outline"
              />
              Contains Errors
            </v-chip>
          </v-slide-x-reverse-transition>
        </v-toolbar>

        <draggable
          v-model="value"
          handle=".handle"
          :sort="sortable"
          item-key="_key"
        >
          <template #item="{ element: item, index }">
            <v-card
              flat
              border
              class="mb-4"
            >
              <v-toolbar
                flat
                density="compact"
                color="transparent"
                @click.stop="toggle(item, index)"
              >
                <v-app-bar-nav-icon
                  v-if="sortable"
                  class="handle"
                >
                  <v-icon>mdi-drag</v-icon>
                </v-app-bar-nav-icon>

                <v-toolbar-title>
                  {{
                    useValues(rowLabel, `Row ${index}`, {
                      ...item,
                      index: index + 1
                    })
                  }}
                </v-toolbar-title>

                <v-spacer />

                <v-btn
                  icon="mdi-delete"
                  size="small"
                  @click.stop="remove(index)"
                />

                <v-btn
                  :icon="item.isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
                  size="small"
                  :disabled="hasErrors(index)"
                  @click.stop="toggle(item, index)"
                />
              </v-toolbar>

              <v-expand-transition>
                <v-card-text
                  v-show="item.isOpen"
                  class="mt-n4"
                >
                  <div
                    v-for="field in uischema?.fields"
                    :key="`item-${index}-${field.name}`"
                    class="mt-4"
                  >
                    <component
                      :is="
                        is(field, getFieldSchema(field.name, schema), {
                          ...item,
                          index
                        })
                      "
                      v-bind="bind(field, schema?.items, { ...item, index })"
                      :name="`${name}.${index}.${field.name}`"
                    />
                  </div>
                </v-card-text>
              </v-expand-transition>
            </v-card>
          </template>
        </draggable>

        <v-toolbar
          class="w-100 mb-4"
          density="compact"
          rounded
          color="transparent"
        >
          <v-spacer />
          <v-btn
            :disabled="(value?.length && hasErrors()) || (max && value?.length >= max)"
            icon="mdi-plus"
            variant="tonal"
            @click.prevent="add(schema)"
          />
          <v-spacer />
        </v-toolbar>
      </div>
    </v-field>
  </v-input>
</template>

<script>
import { defineComponent } from 'vue';
import { useMatterificFieldArray } from '#imports';
import { components } from '@matterific/composables/useMatterificFields';
import { includes, uniqueId, map } from 'lodash-es';
import Draggable from 'vuedraggable';
import { useValues } from '@matterific/utils';
import MtfFieldIcon from '../FieldIcon.vue';

export default defineComponent({
  name: 'MtfFieldRepeater',
  components: {
    ...components,
    Draggable,
    MtfFieldIcon
  },
  inheritAttrs: true,
  customOptions: {},
  // ----------------
  props: {
    schema: { type: Object, required: true },
    uischema: { type: Object, required: true },
    name: { type: String, required: true },
    label: { type: String, required: true },
    visible: { type: Boolean, default: true },
    // ---
    min: Number,
    max: Number,
    rowLabel: [String, Function, Object, Array],
    sortable: { type: Boolean, default: true }
  },
  //emits: ['update:modelValue', 'input',],

  // ----------------

  setup(props, context) {
    const {
      add,
      bind,
      errors,
      getFieldSchema,
      handleBlur,
      hasErrors,
      is,
      isVisible,
      meta,
      model,
      move,
      remove,
      swap,
      toggle,
      validate,
      value
    } = useMatterificFieldArray(props.name, props.schema, context);

    const safeValue = computed({
      get: () => {
        const rows = value.value; // this will be an array
        const safeValue = map(rows, (row) => {
          row._key ??= uniqueId('row_'); // this is a private prop for Draggable and will be stripped out on save
          return row;
        });
        return safeValue;
      },
      set: (val) => {
        value.value = val;
      }
    });

    return {
      meta,
      value: safeValue,
      errors,

      add,
      bind,
      getFieldSchema,
      handleBlur,
      hasErrors,
      includes,
      is,
      isVisible,
      model,
      move,
      remove,
      swap,
      toggle,
      useValues,
      validate
    };
  }
});
</script>
